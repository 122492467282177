import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, isEqual } from 'lodash';
import classNames from 'classnames';
import InputText from '../input-text';
import CurrentUserAvatar from '../../containers/current-user-avatar';
import Button from '../button';
import RichContentEditor from '../rich-content-editor-async';
import { getIsAutoSuggestionsEnabled } from '../../selectors/app-settings-selectors';
import postFormSettings from '../../services/post-form-settings';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import { withFastForm } from '../../../common/components/fast-form';
import { connect } from '../../../common/components/runtime-context';
import {
  POST_FORM_CATEGORY_REQUIRED,
  POST_FORM_TITLE_REQUIRED,
  POST_FORM_UPLOAD_IN_PROGRESS,
} from '../messages/message-types';
import PostAutoSuggestions from '../post-auto-suggestions';
import getTheme from '../rich-content-editor/theme-post-form';
import {
  EXPERIMENT_NEW_QUESTION_PLACEHOLDER,
  EXPERIMENT_POST_CREATION_GUIDELINES_CLIENT,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withExperiment from '../../hoc/with-experiment';

import styles from './post-form-desktop.scss';
import rceTheme from '../rich-content-editor/theme.scss';
import { getQuestionPlaceholder } from './post-form-common';
import { withEditorContext } from 'wix-rich-content-editor-common/libs/EditorEventsContext';
import CategoryDropdownSelect from '../category-dropdown-select';
import { TextButton } from 'wix-ui-tpa/TextButton';
import PostGuidelinesSidebar from '../post-guidelines-sidebar';
import {
  areGuidelinesAvailable,
  areAnyGuidelinesAvailable,
  areGuidelinesVisible,
} from '../../selectors/guidelines-selectors';
import { CSSTransitionGroup } from 'react-transition-group';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { classes } from './post-form-desktop.st.css';
import withPermissions from '../../hoc/with-permissions';
import { getCategory } from '../../../common/selectors/categories-selectors';

export class PostForm extends Component {
  constructor(props) {
    super(props);
    const { content, ...values } = props.fastForm.values;

    this.state = {
      initialValues: values,
      initialContent: content,
      isLocalSuggestionsEnabled: true,
      isTitleInputFocused: false,
      isCategoryErrorVisible: false,
      isCategoryChanged: false,
    };
  }
  componentDidMount() {
    if (this.titleInputRef) {
      this.titleInputRef.addEventListener('focus', this.handleInputFocus);
      this.titleInputRef.addEventListener('blur', this.handleInputBlur);
    }
  }

  componentDidUnmount() {
    if (this.titleInputRef) {
      this.titleInputRef.removeEventListener('focus', this.handleInputFocus);
      this.titleInputRef.removeEventListener('blur', this.handleInputBlur);
    }
  }

  handleInputFocus = () => this.setState({ isTitleInputFocused: true });

  handleInputBlur = () => this.setState({ isTitleInputFocused: false });

  handleDrop = event => event.preventDefault();

  handleCancelClick = () => {
    this.props.onCancel(this.hasChanges({}));
  };

  hasChanges = ({ localContent, localTitle }) => {
    const { content, ...rest } = this.props.fastForm.values;
    const { initialValues, initialContent } = this.state;
    return (
      !isEqual({ ...rest, title: localTitle ?? rest.title }, initialValues) ||
      !isEqual(localContent ?? content, initialContent)
    );
  };

  handleToggleSuggestions = () => {
    const newSuggestionsValue = !this.state.isLocalSuggestionsEnabled;

    this.props.toggleSuggestionsClicked(newSuggestionsValue);
    this.setState({ isLocalSuggestionsEnabled: newSuggestionsValue });
    this.titleInputRef && this.titleInputRef.focus();
  };

  onContentChange = value => {
    if (!this.state.initialContent) {
      this.setState({ initialContent: value });
    }
    this.props.fastForm.changeValue('content')(value);

    if (this.props.onContentChange) {
      this.props.onContentChange(this.hasChanges({ localContent: value }));
    }
  };

  renderAvatar() {
    return <CurrentUserAvatar type={CurrentUserAvatar.POST_PAGE} showUserInfoInline />;
  }

  setEditorRef = ref => {
    this.editorRef = ref;
  };

  renderTextEditor() {
    const {
      fastForm,
      postType,
      t,
      isNewQuestionPlaceholderEnabled,
      postId,
      can,
      category,
    } = this.props;
    return (
      <div className={rceTheme.richContentEditor}>
        <RichContentEditor
          setRef={this.setEditorRef}
          placeholder={t(
            isDiscussion(postType)
              ? 'text-editor.placeholder'
              : getQuestionPlaceholder(isNewQuestionPlaceholderEnabled, t),
          )}
          initialState={fastForm.values.content || undefined}
          onChange={this.onContentChange}
          externalModalsEnabled
          origin="post"
          themeGetter={getTheme}
          contentId={postId}
          showAdvancedLinkSettings={can('edit', 'category', category)}
        />
      </div>
    );
  }

  render() {
    const {
      postId,
      onSubmitButtonClick: handleSubmitButtonClick,
      t,
      borderWidth,
      contentFontClassName,
      titleFontClassName,
      fastForm: { errors, values, changeValue, isSubmitting, isValid },
      forPublicUser,
      showCategorySelect,
      postType,
      showMessage,
      isAutoSuggestionsEnabled,
      isPostCreationGuidelinesEnabled,
      areGuidelinesAvailableFn,
      areAnyGuidelinesAvailable,
      areGuidelinesVisibleFn,
      onHideGuidelines,
      onShowGuidelines,
      isSSR,
    } = this.props;
    const {
      isLocalSuggestionsEnabled,
      isTitleInputFocused,
      isCategoryErrorVisible,
      isCategoryChanged,
    } = this.state;
    const titleValue = values.title || '';
    const onTitleChange = value => {
      changeValue('title')(value);

      if (this.props.onContentChange) {
        this.props.onContentChange(this.hasChanges({ localTitle: value.target.value }));
      }
    };
    const containerClassName = classNames(styles.form, 'forum-text-color', contentFontClassName);
    let isButtonDisabled = isSubmitting || !isValid;
    let areGuidelinesForCategoryAvailable = false;
    let areGuidelinesForCategoryVisible = false;

    if (isPostCreationGuidelinesEnabled && areAnyGuidelinesAvailable) {
      isButtonDisabled = isButtonDisabled || !values.categoryId;
      areGuidelinesForCategoryAvailable = areGuidelinesAvailableFn(values.categoryId);
      areGuidelinesForCategoryVisible = areGuidelinesVisibleFn(values.categoryId);
    }

    let buttonTooltipText;
    let a11yHandler = () => {};

    if (errors.title) {
      buttonTooltipText = t('post-form.require-title');
      a11yHandler = () => {
        showMessage(POST_FORM_TITLE_REQUIRED);
        this.titleInputRef && this.titleInputRef.focus();
      };
    } else if (errors.content) {
      a11yHandler = () => showMessage(POST_FORM_UPLOAD_IN_PROGRESS);
      buttonTooltipText = t('post-form.uploading');
    } else if (isPostCreationGuidelinesEnabled && areAnyGuidelinesAvailable && !values.categoryId) {
      // After experiment is merged, validator for categoryId should be created.
      a11yHandler = () => {
        showMessage(POST_FORM_CATEGORY_REQUIRED);
        this.setState({ isCategoryErrorVisible: true });
      };
      buttonTooltipText = t('post-form.require-category');
    }

    const isDiscussionType = isDiscussion(postType);
    const shouldShowSuggestions = isAutoSuggestionsEnabled && titleValue.length !== 0;
    return (
      <div
        className={classNames(
          containerClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        {isPostCreationGuidelinesEnabled && (
          <div className={classNames(styles.sectionHeader)}>
            {!postId && areAnyGuidelinesAvailable && (
              <div className={classNames(styles.categorySelect)}>
                <CategoryDropdownSelect
                  error={isCategoryErrorVisible}
                  initiallySelectedId={values.categoryId}
                  onChange={category => {
                    this.setState({
                      isCategoryErrorVisible: false,
                      isCategoryChanged: true,
                    });
                    changeValue('categoryId')(category._id);
                  }}
                />
              </div>
            )}
            {areGuidelinesForCategoryAvailable && areGuidelinesForCategoryVisible && (
              <TextButton
                data-hook="toggle-guidelines"
                className={classNames(styles.guidelinesTrigger, classes.button)}
                priority="primary"
                onClick={() => onHideGuidelines(values.categoryId)}
              >
                {t('post-form.hide-guidelines')}
              </TextButton>
            )}
            {areGuidelinesForCategoryAvailable && !areGuidelinesForCategoryVisible && (
              <TextButton
                data-hook="toggle-guidelines"
                className={classNames(styles.guidelinesTrigger, classes.button)}
                priority="primary"
                onClick={() => onShowGuidelines(values.categoryId)}
              >
                {t('post-form.show-guidelines')}
              </TextButton>
            )}
          </div>
        )}
        <div className={styles.areaWrapper}>
          <div className={styles.mainAreaWrapper}>
            <div
              className={classNames(styles.mainArea, 'forum-card-border-color')}
              style={{ borderWidth }}
            >
              <div className={styles.header}>{this.renderAvatar()}</div>
              <div className={styles.titleInputWrapper}>
                <InputText
                  value={titleValue}
                  onChange={onTitleChange}
                  containerClassName={titleFontClassName}
                  className={classNames(styles.titleInput, 'forum-text-color')}
                  maxLength={140}
                  aria-label={t('post-form.title')}
                  placeholder={t(
                    isDiscussionType
                      ? 'post-form.give-this-post-a-title'
                      : 'post-form.your-question-here',
                  )}
                  onDrop={this.handleDrop}
                  data-hook="post-form__title-input"
                  inputRef={r => (this.titleInputRef = r)}
                  autoFocus
                />
              </div>
              {shouldShowSuggestions && (
                <PostAutoSuggestions
                  title={titleValue}
                  handleDisableSuggestions={this.handleToggleSuggestions}
                  isTitleInputFocused={isTitleInputFocused}
                  isLocalSuggestionsEnabled={isLocalSuggestionsEnabled}
                />
              )}
              {!isLocalSuggestionsEnabled && (
                <button
                  className={classNames(styles.textButton, 'button-color')}
                  onClick={this.handleToggleSuggestions}
                  data-hook="turn-on-suggestions"
                >
                  {t('post-auto-suggestions.turn-on-suggestions')}
                </button>
              )}
              <div className={classNames(styles.content, 'post-form__text-editor')}>
                {this.renderTextEditor()}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.editorControls} />
              <div className={styles.buttons}>
                <span className={styles.button}>
                  <Button isSecondary onClick={this.handleCancelClick} isSmall>
                    {t('post-form.cancel')}
                  </Button>
                </span>
                <span className={styles.button}>
                  <Button
                    onClick={
                      !isButtonDisabled
                        ? forPublicUser(() =>
                            handleSubmitButtonClick(async () => {
                              if (this.props.editorEvents) {
                                const content = await this.props.editorEvents.publish();
                                this.props.fastForm.changeValue('content')(content);
                              }
                              this.props.fastForm.submit();
                            }),
                          )
                        : forPublicUser(a11yHandler)
                    }
                    tooltipText={buttonTooltipText}
                    isLoading={isSubmitting}
                    data-hook="post-form__publish-button"
                    aria-label={isSubmitting ? t('post-form.submitting') : ''}
                    isSmall
                  >
                    {showCategorySelect && !isPostCreationGuidelinesEnabled
                      ? t('post-form.next')
                      : postId
                      ? t('post-form.update')
                      : t('post-form.publish')}
                  </Button>
                </span>
              </div>
            </div>
          </div>
          {!isSSR && areGuidelinesForCategoryAvailable && (
            <CSSTransitionGroup
              transitionName="slide"
              transitionEnterTimeout={800}
              transitionAppearTimeout={800}
              transitionLeaveTimeout={0}
              component="div"
            >
              {areGuidelinesForCategoryVisible && (
                <PostGuidelinesSidebar
                  categoryId={values.categoryId}
                  onClose={() => onHideGuidelines(values.categoryId)}
                  hasCategoryChanged={isCategoryChanged}
                  isEdit={!!postId}
                />
              )}
            </CSSTransitionGroup>
          )}
        </div>
      </div>
    );
  }
}

PostForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onContentChange: PropTypes.func,
  onSubmitButtonClick: PropTypes.func.isRequired,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func,
  postId: PropTypes.string,
  categoryId: PropTypes.string,
  showCategorySelect: PropTypes.bool,
  forPublicUser: PropTypes.func,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object,
  postType: PropTypes.string,
  isAutoSuggestionsEnabled: PropTypes.bool,
  toggleSutggestionsClicked: PropTypes.func,
  isNewQuestionPlaceholderEnabled: PropTypes.bool,
  areGuidelinesAvailableFn: PropTypes.func.isRequired,
  areAnyGuidelinesAvailable: PropTypes.bool.isRequired,
  areGuidelinesVisibleFn: PropTypes.func.isRequired,
  onHideGuidelines: PropTypes.func.isRequired,
  onShowGuidelines: PropTypes.func.isRequired,
  isSSR: PropTypes.bool,
  category: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  showMessage: actions.showMessage,
  isAutoSuggestionsEnabled: getIsAutoSuggestionsEnabled(state, host.style),
  toggleSuggestionsClicked: status =>
    actions.buttonClicked({
      name: 'toggle_suggestions',
      isEnabled: status,
      categoryId: ownProps.categoryId,
    }),
  areGuidelinesAvailableFn: areGuidelinesAvailable(state),
  areAnyGuidelinesAvailable: areAnyGuidelinesAvailable(state),
  areGuidelinesVisibleFn: areGuidelinesVisible(state),
  onHideGuidelines: categoryId => actions.hideGuidelines(categoryId),
  onShowGuidelines: categoryId => actions.showGuidelines(categoryId),
  isSSR: isSSR(state),
  category: getCategory(state, ownProps.categoryId),
});

export default flowRight(
  withFastForm(postFormSettings),
  connect(mapRuntimeToProps),
  withTranslate,
  withCardBorderWidth,
  withFontClassName,
  withAuth,
  withExperiment({
    isNewQuestionPlaceholderEnabled: EXPERIMENT_NEW_QUESTION_PLACEHOLDER,
    isPostCreationGuidelinesEnabled: EXPERIMENT_POST_CREATION_GUIDELINES_CLIENT,
  }),
  withEditorContext,
  withPermissions,
)(PostForm);
